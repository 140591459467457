import { GoogleOAuthProvider } from "@react-oauth/google";
import { ConfigProvider } from "antd";
import moment from "moment-timezone";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./authInterceptor";
import "./common.css";
import "./index.css";
import "./overrides.css";
import reportWebVitals from "./reportWebVitals";
import { Router } from "./router";
import ScrollToTop from "./scrollTop";
import store from "./store";

let GOOGLE_OAUTH_CLIENT_ID =
  "924170610320-f1s1ttg3kns0a5clt9hbbcfgtn6d9s7h.apps.googleusercontent.com";
if (process.env.NODE_ENV === "production") {
  GOOGLE_OAUTH_CLIENT_ID =
    "924170610320-felkqgs49p8o8as8ttcj7ikmukahjp2c.apps.googleusercontent.com";
}

moment.tz.setDefault("America/Los_Angeles");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#4867b1",
          },
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
