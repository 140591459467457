import { GoogleOutlined } from "@ant-design/icons";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Col, message, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo_full.svg";
import styles from "./Auth.module.css";
import { authSelector, clearErrors, googleAuth, login } from "./authSlice";

export function Login() {
  const { currentUser, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser.id) {
      navigate("/");
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        dispatch(googleAuth(response)).then((res) => {
          if (res.type === "user/googleAuth/fulfilled") {
            message.success("Login successful");
          } else {
            message.error(
              Array.isArray(res.errors) ? res.errors[0] : "Login failed"
            );
          }
        });
      } catch (error) {
        message.error(
          "Error during Google login:",
          error.response?.data || error.message
        );
      }
    },
    onError: (error) => {
      message.error("Google login failed:", error.message);
    },
  });

  return (
    <div className={styles.loginContainer}>
      <Row>
        <Col span={24} className="ta-c mb-20">
          <img src={logo} alt="logo" height="25" />
        </Col>
        <Col span={24} className="ta-c">
          <Button
            className="mt-35"
            type="primary"
            size="large"
            icon={<GoogleOutlined />}
            onClick={() => handleGoogleAuth()}
          >
            Sign in with Google
          </Button>
        </Col>
      </Row>
    </div>
    // <div className={styles.loginContainer}>
    //   <Form
    //     className={styles.loginForm}
    //     size="large"
    //     initialValues={{
    //       remember: true,
    //     }}
    //     onFinish={handleSubmit}
    //   >
    //     <Form.Item className="ta-c">
    //       <img src={logo} alt="logo" height="35" />
    //     </Form.Item>
    //     <br />
    //     <Form.Item
    //       name="email"
    //       className="mt-25"
    //       rules={[
    //         {
    //           required: true,
    //           message: "Please input your Email!",
    //         },
    //       ]}
    //     >
    //       <Input
    //         prefix={<MailOutlined className="prefixIcon" />}
    //         placeholder="Email"
    //         type="text"
    //       />
    //     </Form.Item>
    //     <Form.Item
    //       name="password"
    //       rules={[
    //         {
    //           required: true,
    //           message: "Please input your Password!",
    //         },
    //       ]}
    //     >
    //       <Input.Password
    //         prefix={<LockOutlined className="prefixIcon" />}
    //         type="password"
    //         placeholder="Password"
    //       />
    //     </Form.Item>

    //     {/* <br /> */}
    //     {/* <Form.Item> */}
    //     <Button
    //       type="primary"
    //       htmlType="submit"
    //       className="w-100"
    //       loading={loading}
    //     >
    //       Log In
    //     </Button>
    //     {/* </Form.Item> */}
    //     {/* <div className="ta-c mt-20 fs-2">
    //       <Link to="/forgot_password">Forgot Password?</Link>
    //     </div> */}
    //   </Form>
    //   <br />
    // </div>
  );
}
