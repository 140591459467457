import {
  FrownFilled,
  LoadingOutlined,
  MehFilled,
  SearchOutlined,
  SmileFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Metric } from "../../components/Metric/Metric";
import { getPersona } from "../../constants";
import { metricsSelector, refreshMetrics } from "../nav/navSlice";
import { Chats } from "./Chats";
import {
  clearConversations,
  conversationsSelector,
  getConversation,
  getConversations,
} from "./conversationsSlice";

const PAGE_SIZE = 50;

export function Conversations(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: conversations,
    loading,
    errors,
    totalPages,
    totalCount,
  } = useSelector(conversationsSelector);
  const { data: metrics, loading: metricsLoading } =
    useSelector(metricsSelector);
  const [pageNum, setPageNum] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [filters, setFilters] = useState({ scope: "casey" });
  const [currentConversation, setCurrentConversation] = useState();
  const [chatsOpened, setChatsOpened] = useState(false);

  useEffect(() => {
    if (props.id) {
      dispatch(
        getConversation({
          conversationId: props.id,
        })
      ).then((res) => {
        if (res.type === "conversations/show/fulfilled") {
          setCurrentConversation(res.payload);
          setChatsOpened(true);
        } else {
          message.error({
            content: "Unable to retrieve conversation",
          });
        }
      });
    }
  }, [props.id]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    dispatch(
      refreshMetrics({
        filter: "conversations",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearConversations());
    dispatch(
      getConversations({
        page: 1,
        search: "",
        filters: filters,
      })
    );
  }, [dispatch]);

  const getTimeDiff = (start, finish) => {
    const diff = Math.abs(new Date(finish) - new Date(start));
    const m = Math.floor(diff / 60000);
    const s = Math.floor((diff % 60000) / 1000);
    return `${m}m ${s}s`;
  };

  const columns = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <>
            <span
              className="link"
              onClick={() => {
                setCurrentConversation(record);
                setChatsOpened(true);
              }}
            >
              {text}
            </span>
          </>
        ),
      },
      {
        title: "Duration",
        key: "duration",
        render: (record) => (
          <>
            {record.failed ? (
              <Tag color="red">Error</Tag>
            ) : record.control_id ? (
              record.finished_at ? (
                record.name === "Casey Call" ? (
                  <Tag>
                    {getTimeDiff(record.created_at, record.finished_at)}
                  </Tag>
                ) : (
                  <></>
                )
              ) : (
                <Tag icon={<LoadingOutlined />} color="green">
                  Live
                </Tag>
              )
            ) : (
              <></>
            )}
          </>
        ),
      },

      {
        title: "Messages",
        dataIndex: "chats_c",
        key: "chats_c",
      },
      {
        title: "From",
        key: "from",
        render: (record) => (
          <>
            <Link to={`/users/${record.user_id}`}>{record.user_id}</Link>
            {record.node_id ? " & " : ""}
            <Link to={`/nodes/${record.node_id}`}>{record.node_id}</Link>
          </>
        ),
      },
      {
        title: "Current Persona",
        dataIndex: "current_persona_id",
        key: "current_persona_id",
        render: (p) => getPersona(p),
      },
      {
        title: "Last message at",
        dataIndex: "last_chat_at",
        key: "last_chat_at",
        render: (text) => (
          <div size="middle">
            <div>{new Date(text).toLocaleString()}</div>
          </div>
        ),
      },
      {
        title: "Sentiment",
        dataIndex: "sentiment",
        key: "sentiment",
        render: (s) => {
          if (s?.emotion) {
            return (
              s.emotion && (
                <Tooltip title={s.reason} color="#4867b1">
                  {s.emotion === "positive" ? (
                    <SmileFilled
                      className="fs-3"
                      style={{ color: "#49aa19" }}
                    />
                  ) : s.emotion === "negative" ? (
                    <FrownFilled style={{ color: "#dc4446" }} />
                  ) : (
                    <MehFilled style={{ color: "#faad14" }} />
                  )}
                </Tooltip>
              )
            );
          }
        },
      },
    ],
    []
  );

  const search = () => {
    setPageNum(1);
    dispatch(
      getConversations({
        page: 1,
        search: searchString,
        filters: filters,
      })
    );
  };

  const onPageChange = (page) => {
    setPageNum(page);
    dispatch(
      getConversations({
        page: page,
        search: searchString,
        filters: filters,
      })
    );
  };

  return (
    <>
      <div className="container">
        <div className="mb-15">
          <Row justify="space-between" wrap>
            <Col>
              <span className="fs-5 fw-300">Conversations</span>
            </Col>
          </Row>
        </div>
        <div className="mb-30">
          <Row gutter={24}>
            <Col span={6}>
              <Metric
                title="Total Conversations"
                current={metrics["total_c"]}
                loading={metricsLoading}
              />
            </Col>
            <Col span={10}>
              <Row>
                <Col span={12}>
                  <Metric
                    // title="New Messages (last 7 days PST)"
                    title="New Messages"
                    current={metrics["last_7_days"]}
                    loading={metricsLoading}
                  />
                </Col>
                {/* <Col span={12}>
                  <Metric
                    title="Change"
                    current={metrics["last_7_days"]}
                    old={metrics["prev_7_days"]}
                    loading={metricsLoading}
                  />
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="container bg-white b-shadow">
          <Row className="mb-20" gutter={12}>
            {/* <Col span={24}>
              <Search
                allowClear={false}
                size="large"
                onSearch={search}
                enterButton={
                  <Button type="primary" ghost icon={<SearchOutlined />} />
                }
                placeholder="Search id, user id, node id or name..."
              />
            </Col> */}
            <Col span={3}>
              <Select
                value={filters["scope"]}
                className="w-100"
                onChange={(val) => setFilters({ ...filters, scope: val })}
                options={[
                  {
                    value: "casey",
                    label: "Casey",
                  },
                  {
                    value: "partner",
                    label: "Partners",
                  },
                ]}
              />
            </Col>
            <Col span={3}>
              <Select
                className="w-100"
                placeholder="Sentiment"
                allowClear
                onChange={(val) => setFilters({ ...filters, emotion: val })}
                options={[
                  {
                    value: "positive",
                    label: "Positive",
                  },
                  {
                    value: "neutral",
                    label: "Neutral",
                  },
                  {
                    value: "negative",
                    label: "Negative",
                  },
                ]}
              />
            </Col>
            <Col span={15}>
              <Input
                placeholder="Search id, user id, node id or name..."
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Col>

            <Col span={3}>
              <Button
                icon={<SearchOutlined />}
                className="w-100"
                onClick={search}
                loading={loading}
              >
                Search
              </Button>
            </Col>
          </Row>
          <div className="mt-30">
            <Table
              loading={loading}
              bordered
              size="small"
              columns={columns}
              dataSource={conversations.map((l, i) => {
                return { ...l, ...{ key: i } };
              })}
              pagination={{
                position: ["bottomRight"],
                onChange: onPageChange,
                pageSize: PAGE_SIZE,
                total: totalPages * PAGE_SIZE,
                showSizeChanger: false,
                current: pageNum,
                showTotal: (total) => (
                  <span className="fs-2 c-subtext">{`${totalCount} ${
                    totalCount === 1 ? "conversation" : "conversations"
                  }`}</span>
                ),
              }}
            ></Table>
          </div>
        </div>
      </div>
      <Modal
        destroyOnClose
        title={`${currentConversation?.id} - ${currentConversation?.name}`}
        onCancel={() => {
          setChatsOpened(false);
          if (props.id) {
            navigate("/conversations");
          }
        }}
        open={chatsOpened}
        footer={null}
        width={1000}
        style={{ top: 0 }}
      >
        <Chats conversation={currentConversation} />
        <div className="mt-10"></div>
      </Modal>
    </>
  );
}
