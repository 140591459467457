import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: {},
  loading: false,
  errors: null,
  failed: [],
};

export const getWorkers = createAsyncThunk("workers/list", async (_, thunk) => {
  try {
    let response = await API.workers.getWorkers();
    return response.data;
  } catch (err) {
    return thunk.rejectWithValue(err.response.data);
  }
});

export const getFailedWorkers = createAsyncThunk(
  "workers/failed",
  async (data, thunk) => {
    try {
      let response = await API.workers.getFailedWorkers(data.queue);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const getCaseyWorkers = createAsyncThunk(
  "workers/casey/index",
  async (_, thunk) => {
    try {
      let response = await API.workers.casey.getWorkers();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const retryCaseyJob = createAsyncThunk(
  "workers/casey/retryJob",
  async (data, thunk) => {
    try {
      let response = await API.workers.casey.retryJob(data.worker, data.jobId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const deleteCaseyJob = createAsyncThunk(
  "workers/casey/deleteJob",
  async (data, thunk) => {
    try {
      let response = await API.workers.casey.deleteJob(data.worker, data.jobId);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const workersSlice = createSlice({
  name: "workers",
  initialState,
  extraReducers: {
    [getWorkers.pending]: (state) => {
      state.loading = true;
    },
    [getWorkers.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getWorkers.rejected]: (state, action) => {
      state.loading = false;
      state.data = {};
      state.errors = ["Error during data retrieval!"];
    },
    [getFailedWorkers.pending]: (state) => {
      state.loading = true;
    },
    [getFailedWorkers.fulfilled]: (state, action) => {
      state.loading = false;
      state.failed = action.payload;
    },
    [getFailedWorkers.rejected]: (state, action) => {
      state.loading = false;
      state.failed = [];
      state.errors = ["Error during data retrieval!"];
    },
  },
});

export const workersSelector = (state) => state.workers;

export default workersSlice.reducer;
